
import { mask } from 'vue-the-mask'
import { Component } from 'nuxt-property-decorator'
import InputGrzAbstract from '@simple-payments/shared-packages/classes/search-form/InputGrzAbstract'

@Component({
  directives: { mask }
})
export default class extends InputGrzAbstract {
  // @todo rm when used input-mask from shared-packages
  plateNumMask: any = {
    mask: 'F ### FF',
    tokens: {
      '#': { pattern: /\d/ },
      F: {
        pattern: this.plateNumPattern,
        transform (v: string) {
          const engLetters = 'ABEKMHOPCTYXabekmhopctyx'
          // eslint-disable
          const translations = {
            A: 'А',
            B: 'В',
            E: 'Е',
            K: 'К',
            M: 'М',
            H: 'Н',
            O: 'О',
            P: 'Р',
            C: 'С',
            T: 'Т',
            Y: 'У',
            X: 'Х'
          }
          if (engLetters.includes(v)) {
            return translations[v.toLocaleUpperCase()]
          }
          return v.toLocaleUpperCase()
        }
      }
    }
  }

  mounted () {
    this.fillLicencePlate()
  }
}
