import { render, staticRenderFns } from "./EmailForm.vue?vue&type=template&id=00c0b51e&scoped=true&"
import script from "./EmailForm.vue?vue&type=script&lang=ts&"
export * from "./EmailForm.vue?vue&type=script&lang=ts&"
import style0 from "./EmailForm.vue?vue&type=style&index=0&id=00c0b51e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c0b51e",
  null
  
)

export default component.exports