
import { Component, Vue } from 'nuxt-property-decorator'
import DocWidgetList from '@simple-payments/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import ArticlesThree from '@simple-payments/shared-packages/components/Articles/ArticlesThree.vue'
import SearchForm from '~/components/SearchForm.vue'
import Banner from '~/components/Banner.vue'
import FnsIndexWidgets from '~/components/index/FnsIndexWidgets.vue'

@Component({
  components: {
    FnsIndexWidgets,
    DocWidgetList,
    Banner,
    SearchForm,
    ArticlesThree
  }
})
export default class extends Vue {
  head () {
    return {
      title: 'Проверка и оплата штрафов ГИБДД онлайн по номеру, водительскому удостоверению, СТС',
      link: [
        { rel: 'preload', as: 'image', href: require('~/assets/img/gibdd.webp') }
      ]
    }
  }

  beforeMount () {
    window.addEventListener('load', () => {
      this.$analytics.sendEvent('main')
    }, { once: true })
  }

  mounted () {
    if (this.$route.query.email) {
      this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }
  }
}
